import React from 'react'
import './ApplicationForm.css'
import { Input, Label, FormGroup, Form, CustomInput, Button, Progress, Alert, Row, Col, Card, CardBody } from 'reactstrap'
import ImageTools from './ImageTools'
import moment from 'moment'
import dummyDMV from "../images/dummy_dmv.png"
import formSub from '../images/form-submission2.gif'
import language from '../language.json'
import buggyLogo from '../images/logo-buggy-color-sec.svg'
import ucarsLogo from '../images/ucarsLogo.jpg'
import fasttrackLogo from '../images/fasttrackLogo.png'
import { BRANCH_CONFIG } from '../branch_settings'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const referralOptions = ["Google", "Instagram", "Youtube", "Facebook", "Uber", "Friend"]
const docTypeOptions = [{ label: "MVR", value: "RHJpdmVyRG9jdW1lbnRUeXBlTm9kZTo5" }, { label: "Police Report", value: "RHJpdmVyRG9jdW1lbnRUeXBlTm9kZTo0" }, { label: "Foreign License", value: "RHJpdmVyRG9jdW1lbnRUeXBlTm9kZTo3" }, { label: "Additional DMV", value: "RHJpdmVyRG9jdW1lbnRUeXBlTm9kZTozMw==" }, { label: "Additional TLC", value: "RHJpdmVyRG9jdW1lbnRUeXBlTm9kZTozNA==" }, { label: "Uber Profile", value: "RHJpdmVyRG9jdW1lbnRUeXBlTm9kZTozMg==" }, { label: "Other", value: "Other" }]
const docTypeDMV = "Driver License"
const docTypeTLC = "TLC"
const docTypeMxId = "INE (anverso)"
const otherDocType = "Other"
const requiredDocVeficationKeysDMV = ["Region", "Document Number", "First Name", "Last Name", "ZIP Code", "Birth Date", "State", "Street Address", "Full Address", "Issue Date", "Expiration Date"]
const requiredDocVeficationKeysTLC = ["Document Number", "First Name", "Last Name", "Expiration Date", "Title"]
const pickupLocations = [
    { label: "New York City, NY", value: "BROOKLYN_NY", tenant: "buggy" },
    { label: "Las Vegas, NV", value: "FHV_Las_Vegas_NV_", tenant: "buggy" },
    { label: "Orlando, FL", value: "Orlando_FL_", tenant: "buggy" },
    { label: "Miami, FL", value: "MIAMI_FL", tenant: "buggy" },
    { label: "Atlanta, GA", value: "Atlanta_GA", tenant: "buggy" },
    { label: "FastTrack TLC, NY", value: "FASTTRACK_BROOKLYN", tenant: "fasttrack" },
    { label: "Dallas, TX", value: "UCars_Dallas_TX", tenant: "ucars" },
    { label: "Phoenix, AZ", value: "UCars_Phoenix_AZ", tenant: "ucars" },
    { label: "Houston, TX", value: "HOUSTON_TX", tenant: "ucars" },
    { label: "Philadelphia, PA", value: "Philadelphia_PA", tenant: "buggy" },
    { label: "Tampa, FL", value: "TAMPA_FL", tenant: "buggy" },
    { label: "Boston, MA", value: "Boston_MA", tenant: "buggy" },
    { label: "Washington, DC", value: "Washington_DC", tenant: "buggy" },
    { label: "Mexico City", value: "CDMX_MX", tenant: "minave" },
    { label: "Guadalajara", value: "GDL_MX", tenant: "minave" },
    { label: "Puebla", value: "PU_MX", tenant: "minave" },
    { label: "Toluca", value: "TOL_MX", tenant: "minave" },
]

export default class ApplicationFormMiNave extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            referred_by: "",
            source: null,
            applicationURL: "",
            first_name: "", last_name: "", phone: "", email: "", dmv_license: "", tlc_license: "", referred_by_contact: "", name_on_tlc: "", name_on_dmv: "",
            type_of_car_wanted: "", DOB: "", zip_code: "", street_address: "", issue_date: "", expiration_date_tlc: "", expiration_date_dmv: "", user_comment: "",
            city: "", state: "", preferred_language: "", pickup_location: "", base_url: "", gender: "O",
            documents: [],
            documentType: "",
            required: ["first_name", "last_name", "phone", "email", "dmv_license", "tlc_license"],
            formValid: false,
            page: 0,
            error: null,
            loading: false,
            verficationDocLoading: false,
            customId: "",
            tlcLicenseVerified: false,
            emailVerified: false,
            moreDocs: [],
            openModal: true,
            show_comment_box: "hide",
            show_preferred_language: "hide",
            title: "",
            fileTypeError: "",
            contact_info_on_website: "",
            key: "",
            logo: "",
            tenant: "",
        }
    }
    addElement = () => {
        this.setState((prevState) => ({ moreDocs: prevState.moreDocs.concat({ key: Date.now() }) }))
    };

    removeElement = (id) => {
        this.setState((prevState) => ({ moreDocs: prevState.moreDocs.filter((doc) => doc.key !== id) }))
    };

    submitApplication = (e) => {
        e.preventDefault()
        try {
            this.setState({ loading: true })
            let formData = new FormData(e.target)
            let source = this.state.source ? this.state.source : "web"
            let applicationURL = this.state.applicationURL ? this.state.applicationURL : "Website"
            let phone = this.state.phone ? this.state.phone : ""
            if (phone)
                formData.append('phone', this.state.phone)
            if (this.state.DOB !== "")
                formData.append('date_of_birth', this.state.DOB)
            if (this.state.gender !== "")
                formData.append('gender', this.state.gender)
            if (this.state.customId)
                formData.append('branch_custom_id', this.state.customId)
            if (source)
                formData.append('source', source)
            if (applicationURL)
                formData.append('application_url', applicationURL)
            if (this.state.tlc_license === "")
                formData.append('tlc_license', this.state.dmv_license)
            if (this.state.customId === "BROOKLYN_NY")
                formData.append('is_tlc', true)
            else
                formData.append('is_tlc', false)
            if (this.state.documents)
                formData.append("documents", JSON.stringify(this.state.documents))

            if (this.state.email && this.state.emailVerified && this.state.phone && this.state.referred_by && this.state.first_name && this.state.last_name && this.state.preferred_language) {
                fetch(String(process.env.REACT_APP_EVENT_PUBLISHER_URL), {
                    method: "post",
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "message": Object.fromEntries(formData), "topic_name": String(process.env.REACT_APP_PUBSUB_TOPIC_NAME) })
                }).then((response) => {
                    if (response.ok && this.state.documents.length <= 0) {
                        this.setState({ page: 2, error: "", loading: false })
                    } else if (response.ok && this.state.documents.length > 0) {
                        this.setState({ page: 4, error: "", loading: false })
                    } else {
                        this.setState({ error: "Something went wrong. Please try again or call us: " + this.state.contact_info_on_website, loading: false })
                    }
                }).catch((e) => {
                    this.setState({ error: "Something went wrong. Please try again or call us: " + this.state.contact_info_on_website, loading: false })
                })
            } else {
                this.setState({ error: "Información requerida faltante. Favor de llenar todos los campos.", loading: false })
            }
        } catch (err) {
            this.setState({ error: "An error occured during app submission. Please retry." + err, loading: false })
        }
    };


    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === "number") {
            if (isNaN(parseFloat(value)))
                value = 0
            else
                value = parseFloat(value)
        }
        if (name === "tlc_license") {
            this.verifyTlcLicense(value).then((status) => {
                this.setState({ tlcLicenseVerified: status })
            })
        }
        if (name === "email") {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            if (emailRegex.test(value))
                this.setState({ emailVerified: true, error: "" })
            else
                this.setState({ emailVerified: false, error: "Please enter a valid email address.", loading: false })
        }
        this.setState({ [name]: value })
    }


    verifyTlcLicense = async (tlcLicenseNumber) => {
        //Verify TLC License from the NYC Open Data Website. Returns a single array containing license info object
        let nycOpenDataUrl = "https://data.cityofnewyork.us/resource/xjfq-wh2d.json?license_number=" + tlcLicenseNumber
        let appToken = "5WBDkAQLj244SKLuDJmXDVDhT"
        let response = null
        try {
            const res = await fetch(nycOpenDataUrl, {
                method: 'GET',
                data: {
                    "$limit": 1,
                    "$$app_token": appToken
                }
            })
            const data = await res.json()
            //Api returns an array
            if (data && data[0] && data[0].name) {
                let name = data[0].name
                name = name.split(",")
                if (name && name.length > 0) {
                    this.setState({ first_name: name[1], last_name: name[0] })
                    response = true
                }
            }
            else
                response = false
        }
        catch (err) {
            this.setState({ error: "Unable to verify TLC License Number", loading: false })
            response = false
        }
        return response
    }


    allowedFileType = (fileType) => {
        const allowed = ["jpg", "jpeg", "png"]
        let extension = ""
        if (fileType) {
            let split_ = fileType.split("/")
            if (split_.length > 1 && split_[1]) {
                extension = split_[1].toLowerCase()
            }
        }
        return allowed.includes(extension)
    }

    getOCRData = (document, file_type) => {
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
            this.setState({ verficationDocLoading: true })
            fetch(`${String(process.env.REACT_APP_VGM_BASE_URL)}/get_ocr_data/`, {
                method: 'POST',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "file_type": file_type,
                    "file_content": document ? document : "",
                })
            }).then(response => response.json()).then((data) => {
                if (data) {
                    let name_on_dmv = this.state.name_on_dmv
                    let name_on_tlc = this.state.name_on_tlc

                    if (file_type === "US_TLC_LICENSE" && requiredDocVeficationKeysTLC.every(key => data[key].confidenceScore === "High")) {
                        name_on_tlc = data["First Name"].value.replaceAll(",", " ") + " " + data["Last Name"].value.replaceAll(",", " ")
                        if (!this.verifyTlcLicense(data["Document Number"].value.replaceAll(" ", ""))) {
                            this.setState({ verficationDocLoading: false })
                            return false
                        } else {
                            this.setState({ name_on_tlc: data["First Name"].value.replaceAll(",", " ") + " " + data["Last Name"].value.replaceAll(",", " "), tlc_license: data["Document Number"].value.replaceAll(" ", ""), expiration_date_tlc: data["Expiration Date"].value, first_name: data["First Name"].value.replaceAll(",", " "), last_name: data["Last Name"].value, error: "", verficationDocLoading: false, })
                            return true
                        }
                    } else if (file_type === "US_TLC_LICENSE") {
                        this.setState({ verficationDocLoading: false })
                        return false
                    }

                    if (file_type === "US_DRIVER_LICENSE" && requiredDocVeficationKeysDMV.every(key => data[key].confidenceScore === "High")) {
                        name_on_dmv = data["First Name"].value.replaceAll(",", " ") + " " + data["Last Name"].value.replaceAll(",", " ")
                        const validDOBFormats = ["MM/DD/YYYY", "DD-MM-YYYY", "YYYY/MM/DD", "MMMM DD, YYYY", "DD MMMM YYYY", "DD/MM/YY", "MM/DD/YY", "YYYY.MM.DD", "YY.MM.DD"];
                        const DOB = moment(data["Birth Date"].value, validDOBFormats, true);
                        this.setState({ name_on_dmv: data["First Name"].value.replaceAll(",", " ") + " " + data["Last Name"].value.replaceAll(",", " "), dmv_license: data["Document Number"].value.replaceAll(" ", ""), expiration_date_dmv: data["Expiration Date"].value, first_name: data["First Name"].value.replaceAll(",", " "), last_name: data["Last Name"].value, error: "", verficationDocLoading: false, DOB: DOB.isValid() ? DOB.format("YYYY-MM-DD") : "", zip_code: data["ZIP Code"].value, street_address: data["Full Address"].value, issue_date: data["Issue Date"].value, city: data["City"].value, state: data["State"].value, gender: data["Sex"].value })
                        return true
                    } else if (file_type === "US_DRIVER_LICENSE") {
                        this.setState({ verficationDocLoading: false })
                        return false
                    }

                    if (name_on_tlc !== name_on_dmv) {
                        this.setState({ error: "Your name on TLC and DMV do not match", verficationDocLoading: false, })
                    }
                }
            }).catch(error => {
                this.setState({ verficationDocLoading: false })
            })
        }
    }

    updateFile = (e) => {
        let _this = this
        let filename = e.target.name
        let documents = [...this.state.documents]
        let newDocument = {
            documentType: filename,
            base64String: null
        }

        if (this.allowedFileType(e.target.files[0].type)) {
            _this.setState({ fileTypeError: "" })

            ImageTools.resize(e.target.files[0], { width: 500, height: 500 }, function (resizedImage, didItResize) {
                var reader = new FileReader()
                reader.readAsDataURL(resizedImage)

                reader.onload = function (e) {
                    let imageInBase64 = reader.result
                    imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1)
                    newDocument.base64String = imageInBase64
                    documents.push(newDocument)
                    _this.setState({ documents: documents, error: null })
                    let file_type = filename === "Driver License" ? "US_DRIVER_LICENSE" : "US_TLC_LICENSE"
                    if (filename === "Driver License" || filename === "TLC") {
                        _this.getOCRData(imageInBase64, file_type)
                    }
                }
            })
        } else {
            _this.setState({ fileTypeError: "File type not accepted. Please upload a .jpg, .jpeg, or .png file" })
        }
    };

    setPickupLocation = (event) => {
        const newBranch = event.target.value;
        const newUrl = `${this.state.base_url}/?source=${this.state.source ? this.state.source : "web"}&branch=${newBranch}&key=${this.state.key ? this.state.key : "A_1"}`;
        window.location.href = newUrl;
    };


    componentDidMount = () => {
        if (!this.state.source) {
            const url = window.location && window.location.href ? window.location.href : window.location
            let base_url = window.location.origin
            let url_obj = new URL(url)
            let branch_custom_id = url_obj.searchParams.get("branch")
            let application_source = url_obj.searchParams.get("source")
            let key = url_obj.searchParams.get("key")
            let branch_settings = BRANCH_CONFIG[branch_custom_id].find(item => item.template_id === key) ? BRANCH_CONFIG[branch_custom_id].find(item => item.template_id === key) : BRANCH_CONFIG[branch_custom_id].find(item => item.template_id === "A_0")
            this.setState({ source: application_source, customId: branch_custom_id, applicationURL: url, show_comment_box: branch_settings.comment, title: branch_settings.title, show_preferred_language: branch_settings.language, contact_info_on_website: branch_settings.phone, key: key, logo: branch_settings.logo, pickup_location: branch_custom_id, base_url: base_url, tenant: branch_settings.tenant })
        }
    }


    render() {
        let logo = this.state.logo && this.state.logo === "buggy" ? buggyLogo : this.state.logo === "ucars" ? ucarsLogo : this.state.logo === "fasttrack" ? fasttrackLogo : this.state.logo === "minave" ? "https://storage.googleapis.com/download/storage/v1/b/buggy_files/o/public_files%2Fmnave_logo?generation=1689439739320523&alt=media" : ""
        return (
            <div className={"modal-container show"}>
                <div className="modal-body">
                    <div className="applicationForm">
                        <header>
                            <img alt="Buggy Logo" width={"150"} src={logo} /><br />
                        </header>
                        <br /><br />
                        {this.state.page === 0 && <>
                            <Row>
                                <Col>
                                    <Label for="first_name">Selecciona tu ciudad*</Label>
                                    <Input type="select" name="pickup_location" id="pickup_location" value={this.state.pickup_location} onChange={this.setPickupLocation}>
                                        {pickupLocations.filter(item => item.tenant === this.state.tenant).map((item, i) =>
                                            <option key={i} className="option" value={item.value}>{item.label}</option>
                                        )}
                                    </Input>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <Button className="secButton" name="basicInfo" disabled={!this.state.pickup_location} onClick={() => this.setState({ page: 1 })}>Continuar</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                </Col>
                            </Row>
                        </>
                        }
                        <br />
                        {this.state.page === 1 &&
                            <div style={{ justifyContent: "center", display: "flex", alignContent: "center" }}>
                                <h3>{this.state.title ? this.state.title.toUpperCase() : "APLICA AHORA"}</h3>
                            </div>
                        }
                        {this.state.page === 2 && !this.state.error &&
                            <Alert color="success" style={{ fontSize: "12px", borderRadius: 5 }}>
                                <h4 className="alert-heading" >Almost There!</h4>
                                <hr />
                                <p className="mb-0">
                                    Last Step to Complete Your Application!
                                </p>
                            </Alert>
                        }
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <Form name="applicationForm" onSubmit={this.submitApplication}>
                            {this.state.page === 1 &&
                                <div>
                                    <FormGroup>
                                        <Input type="hidden" name="source" value={this.state.source} />
                                        <Input type="hidden" name={docTypeDMV} value={this.state.documents[docTypeDMV]} />
                                        <Input type="hidden" name={docTypeTLC} value={this.state.documents[docTypeTLC]} />
                                        <Input type="hidden" name={docTypeMxId} value={this.state.documents[docTypeMxId]} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="first_name">Ciudad seleccionada</Label>
                                        <Input type="select" name="pickup_location" id="pickup_location" value={this.state.pickup_location} onChange={this.setPickupLocation} disabled={true}>
                                            <option className="option" value="">Please choose one</option>
                                            {pickupLocations.map((item, i) =>
                                                <option key={i} className="option" value={item.value}>{item.label}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="first_name">Primer nombre*</Label>
                                        <Input
                                            type="text"
                                            name="first_name"
                                            onChange={this.updateInput}
                                            placeholder="Escribe tu primer nombre"
                                            value={this.state.first_name}
                                            invalid={!this.state.first_name}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="last_name">Apellidos*</Label>
                                        <Input
                                            type="text"
                                            name="last_name"
                                            onChange={this.updateInput}
                                            placeholder="Escribe tus apellidos"
                                            value={this.state.last_name}
                                            invalid={!this.state.last_name}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="phone">Teléfono*</Label>
                                        <PhoneInput
                                            country={'mx'}
                                            onlyCountries={['mx']}
                                            disableCountryCode
                                            placeholder="(55) 1273-4567"
                                            value={this.state.phone}
                                            onChange={phone => this.setState({ phone: phone })}
                                            inputStyle={{
                                                width: '100%',
                                                height: '38px',
                                                border: this.state.phone ? '1px solid green' : '1px solid red'
                                            }}
                                            inputProps={{
                                                required: true,
                                                disableCountryCode: false
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">E-mail*</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            onChange={this.updateInput}
                                            placeholder="Escribe tu e-mail"
                                            value={this.state.email}
                                            size="30"
                                            invalid={!(this.state.email && this.state.emailVerified)}
                                            valid={this.state.emailVerified}
                                        />
                                    </FormGroup>
                                    {this.state.show_preferred_language === "show" &&
                                        <FormGroup>
                                            <Label for="preferred_language">Idioma preferido*</Label>
                                            <Input type="select" name="preferred_language" id="preferred_language" onChange={this.updateInput} valid={this.state.preferred_language} invalid={!this.state.preferred_language}>
                                                <option className="option" value="">Favor de seleccionar uno</option>
                                                {Object.entries(language).map(([key]) => (
                                                    <option key={key} value={key}>{language[key].name + " - " + language[key].nativeName}</option>
                                                ))});
                                            </Input>
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label for="referred_by">¿Cómo escuchaste de MI NAVE?*</Label>
                                        <Input type="select" name="referred_by" id="referred_by" value={this.state.referred_by} onChange={this.updateInput} valid={this.state.referred_by} invalid={!this.state.referred_by}>
                                            <option className="option" value="">Favor de seleccionar uno</option>
                                            {referralOptions.map((item, i) =>
                                                <option key={i} className="option" value={item}>{item}</option>
                                            )}
                                        </Input>
                                    </FormGroup>
                                    {this.state.referred_by === "Friend" &&
                                        <FormGroup>
                                            <Input
                                                name="referred_by_contact"
                                                placeholder="Enter your friend's number. They're eligible for a $50 referral bonus"
                                                type="phone"
                                                onChange={this.updateInput}
                                            />
                                        </FormGroup>
                                    }
                                    {this.state.show_comment_box === "show" &&
                                        <FormGroup>
                                            <Label for="user_comment">Comment</Label>
                                            <Input
                                                type="textarea"
                                                name="user_comment"
                                                onChange={this.updateInput}
                                                placeholder="Please write anything you want to ask or tell us."
                                                value={this.state.user_comment}
                                            />
                                        </FormGroup>
                                    }
                                    {this.state.loading && <Progress color="info" animated value="100">Processing Application, Please Wait...</Progress>}
                                    <br />
                                </div>
                            }
                            {(this.state.page === 1) &&
                                <div>
                                    <FormGroup className="licenseImagesUpload">
                                        {this.state.verficationDocLoading && <Progress color="info" value="100" size="15px">Verifying Licensce, Please Wait...</Progress>}
                                        <Label for={docTypeDMV}>Subir licencia de conducir (Opcional)</Label>
                                        <CustomInput type="file" onChange={this.updateFile} id={docTypeDMV} name={docTypeDMV} className="d-flex justify-content-center" />
                                    </FormGroup>
                                        <FormGroup className="licenseImagesUpload">
                                            <Label for={docTypeMxId}>Subir INE (Opcional)</Label>
                                            <CustomInput type="file" onChange={this.updateFile} id={docTypeMxId} name={docTypeMxId} className="d-flex justify-content-center" />
                                        </FormGroup>
                                    <Row>
                                        <Col className="d-flex justify-content-end">
                                            <Label onClick={this.addElement} className="add-more-btn">Agregar más documentos</Label>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Label for="moreDocs">Para acelerar la verificación de su aplicación, favor de agregar documentos adicionales, como CURP, RFC, perfil de UBER/DIDI</Label>
                                    {this.state.moreDocs.length > 0 && this.state.moreDocs.map((file) => (
                                        <React.Fragment key={file.key}>
                                            <FormGroup>
                                                <Label for="document_type">Select document type*</Label>
                                                <Input type="select" name={"document_type"} id={"document_type"} onChange={this.updateInput}>
                                                    <option value={otherDocType}>Select document type</option>
                                                    {docTypeOptions.map((item, i) =>
                                                        <option key={i} disabled={this.state.customId !== "BROOKLYN_NY" && item.label === "Additional TLC"} className="option" value={item.label}>{item.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <CustomInput type="file" onChange={this.updateFile} id={this.state.document_type && this.state.document_type !== "" ? this.state.document_type : otherDocType} name={this.state.document_type ? this.state.document_type : otherDocType} disabled={this.state.document_type ? false : true} />
                                                {this.state.moreDocs.length > 1 && <Button type="button" onClick={() => this.removeElement(file.key)} disabled={this.state.moreDocs.length === 1}>Remove</Button>} &nbsp;&nbsp;&nbsp;
                                            </FormGroup>
                                        </React.Fragment>
                                    ))}
                                    {this.state.fileTypeError !== "" &&
                                        <Row>
                                            <Col>
                                                <Alert color="danger">{this.state.fileTypeError}</Alert>
                                            </Col>
                                        </Row>
                                    }
                                    <br /><br />
                                    <Row>
                                        <Col className="d-flex justify-content-start">
                                            <Button className="secButton" name="basicInfo" onClick={() => this.setState({ page: 0 })}>Cambiar de ciudad</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <Button
                                                className="secButton float-right"
                                                name="fileUpload"
                                                disabled={
                                                    this.state.verficationDocLoading ||
                                                    this.state.fileTypeError !== "" ||
                                                    (!this.state.first_name ||
                                                        !this.state.last_name ||
                                                        !this.state.phone ||
                                                        !this.state.email ||
                                                        !this.state.referred_by ||
                                                        !this.state.preferred_language
                                                    )}
                                                onClick={() => this.setState({ page: 3 })}>
                                                Continuar
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {this.state.page === 3 && <>
                                <Alert color="success" style={{ fontSize: "12px", borderRadius: 5 }}>
                                    <h4 className="alert-heading">¡RESUMEN DE LA SOLICITUD!</h4>
                                    <p>
                                        Le solicitamos amablemente que revise y verifique la exactitud de la información proporcionada.
                                        En caso que se encuentre información incorrecta, edite los detalles relevantes.
                                        Este paso es crucial para garantizar un procesamiento fluido y oportuno de su solicitud.
                                        No hacerlo puede resultar en posibles retrasos.
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        Gracias por su atención.
                                    </p>
                                </Alert>
                                <br />
                                <FormGroup>
                                    <Label for="first_name">Primer nombre*</Label>
                                    <Input
                                        type="text"
                                        name="first_name"
                                        onChange={this.updateInput}
                                        placeholder="Escribe tu primer nombre"
                                        value={this.state.first_name}
                                        invalid={!this.state.first_name}
                                        />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="last_name">Apellidos*</Label>
                                    <Input
                                        type="text"
                                        name="last_name"
                                        onChange={this.updateInput}
                                        placeholder="Escribe tus apellidos"
                                        value={this.state.last_name}
                                        invalid={!this.state.last_name}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="phone">Teléfono*</Label>
                                    <PhoneInput
                                        country={'mx'}
                                        onlyCountries={['mx']}
                                        disableCountryCode
                                        placeholder="(55) 1273-4567"
                                        value={this.state.phone}
                                        onChange={phone => this.setState({ phone: phone })}
                                        inputStyle={{
                                            width: '100%',
                                            height: '38px',
                                            border: this.state.phone ? '1px solid green' : '1px solid red'
                                        }}
                                        inputProps={{
                                            required: true,
                                            disableCountryCode: false
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">E-mail*</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        onChange={this.updateInput}
                                        placeholder="Escribe tu e-mail"
                                        value={this.state.email}
                                        size="30"
                                        invalid={!(this.state.email && this.state.emailVerified)}
                                        valid={this.state.emailVerified}
                                    />
                                </FormGroup>
                                {this.state.show_preferred_language === "show" &&
                                    <FormGroup>
                                        <Label for="preferred_language">Idioma preferido*</Label>
                                        <Input type="select" name="preferred_language" id="preferred_language" value={this.state.preferred_language} onChange={this.updateInput} valid={this.state.preferred_language} invalid={!this.state.preferred_language}>
                                            <option className="option" value="">Favor de seleccionar uno</option>
                                            {Object.entries(language).map(([key]) => (
                                                <option key={key} value={key}>{language[key].name + " - " + language[key].nativeName}</option>
                                            ))};
                                        </Input>
                                    </FormGroup>
                                }
                                <FormGroup>
                                    <Label for="referred_by">¿Cómo escuchaste de MI NAVE?*</Label>
                                    <Input type="select" name="referred_by" id="referred_by" value={this.state.referred_by} onChange={this.updateInput} valid={this.state.referred_by} invalid={!this.state.referred_by}>
                                        <option className="option" value="">Favor de seleccionar uno</option>
                                        {referralOptions.map((item, i) =>
                                            <option key={i} className="option" value={item}>{item}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                                {this.state.referred_by === "Friend" &&
                                    <FormGroup>
                                        <Input
                                            name="referred_by_contact"
                                            placeholder="Enter your friend's number. They're eligible for a $50 referral bonus"
                                            type="phone"
                                            onChange={this.updateInput}
                                        />
                                    </FormGroup>
                                }
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            className="secButton float-right"
                                            type="submit"
                                            name="fileUpload"
                                            disabled={
                                                this.state.loading
                                            }
                                        >Submit</Button>
                                    </Col>
                                </Row>
                            </>
                            }
                            {this.state.page === 4 &&
                                <>
                                    <div style={{ "textAlign": "center" }}>
                                        <img alt="Buggy Logo" width={"150"} src={formSub} />
                                    </div>
                                    <Alert color="success" style={{ fontSize: "12px", borderRadius: 5 }}>
                                        <h4 className="alert-heading" >THANK YOU!</h4>
                                        <p>
                                            Thanks a bunch for filling that out. It means a lot to us. We have received your application.
                                            Our representative will get in touch with you shortly.
                                        </p>
                                        <hr />
                                        <p className="mb-0">
                                            If you have any questions, please contact us at: <strong><a href={"tel: " + this.state.contact_info_on_website}>{this.state.contact_info_on_website ? this.state.contact_info_on_website : ""}</a></strong>
                                        </p>
                                    </Alert>

                                </>
                            }
                            <br />
                            {this.state.page != 4 &&
                                <Alert color="info" style={{ fontSize: "12px", borderRadius: 5 }}>
                                    <h4 className="alert-heading" >Requieres ayuda?</h4>
                                    <hr />
                                    <p className="mb-0">
                                        Llámanos al&nbsp;<a href={"tel: " + this.state.contact_info_on_website}>{this.state.contact_info_on_website ? this.state.contact_info_on_website : ""}</a>
                                    </p>
                                </Alert>
                            }
                        </Form>
                    </div>
                </div >
            </div >
        )
    }
}

