const BRANCH_CONFIG = {
    "BROOKLYN_NY": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "FHV_Las_Vegas_NV_": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "Orlando_FL_": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "MIAMI_FL": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "Atlanta_GA": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "FASTTRACK_BROOKLYN": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 718 370 0775", logo: "fasttrack", tenant: "fasttrack" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 718 370 0775", logo: "fasttrack", tenant: "fasttrack" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 718 370 0775", logo: "fasttrack", tenant: "fasttrack" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 718 370 0775", logo: "fasttrack", tenant: "fasttrack" }
    ],
    "UCars_Dallas_TX": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" }
    ],
    "UCars_Phoenix_AZ": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" }
    ],
    "HOUSTON_TX": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "ucars", tenant: "ucars" }
    ],
    "TAMPA_FL": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "Philadelphia_PA": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "Boston_MA": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "Washington_DC": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+1 888 524 8449", logo: "buggy", tenant: "buggy" }
    ],
    "CDMX_MX": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_1", title: "Aplica ahora", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" }
    ],
    "GDL_MX": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_1", title: "Aplica ahora", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" }
    ],
    "PU_MX": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_1", title: "Aplica ahora", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" }
    ],
    "TOL_MX": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_1", title: "Aplica ahora", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "show", phone: "+528006462830", logo: "minave", tenant: "minave" }
    ],
    "EMPIRE_NYC": [
        { template_id: "A_0", title: "Application Form", comment: "hide", language: "hide", phone: "+19179947117", logo: "empirerental", tenant: "empirerental" },
        { template_id: "A_1", title: "Apply Now", comment: "hide", language: "hide", phone: "+19179947117", logo: "empirerental", tenant: "empirerental" },
        { template_id: "A_2", title: "Contact Us", comment: "hide", language: "hide", phone: "+19179947117", logo: "empirerental", tenant: "empirerental" },
        { template_id: "A_3", title: "Uber Rental Program", comment: "hide", language: "hide", phone: "+19179947117", logo: "empirerental", tenant: "empirerental" }
    ]
}


export { BRANCH_CONFIG }