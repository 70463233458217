import './App.css';
import {Container} from 'reactstrap';
// import Footer from './components/Footer';
// import TopNav from './components/TopNav';
import ApplicationFormSelector from './components/ApplicationFormSelector';

function App() {
  return (
    <div className="App">
      <div>
              <Container fluid className="mainContainer">
                <ApplicationFormSelector />
              </Container>
            </div>

    </div>
  );
}

export default App;
