import React from 'react'
import ApplicationForm from './ApplicationForm';
import ApplicationFormMiNave from './ApplicationForm_minave';

export default class ApplicationFormSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            branch: ""
        }
    }

    componentDidMount = () => {
        if (!this.state.branch) {
            const url = window.location && window.location.href ? window.location.href : window.location
            let url_obj = new URL(url)
            let branch_custom_id = url_obj.searchParams.get("branch")
            this.setState({ branch: branch_custom_id })
        }
    }

    render() {
        if (this.state.branch == "CDMX_MX" || this.state.branch == "GDL_MX" || this.state.branch == "PU_MX" || this.state.branch == "TOL_MX") {
            return <ApplicationFormMiNave open={true}/>
        } else {
            return <ApplicationForm open={true}/>
        }
    }
}